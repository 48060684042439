<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>family-grandmother</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3,15v-.75a1.5,1.5,0,0,1,1.5-1.5h0A1.5,1.5,0,0,1,6,14.25v9"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="15" cy="3.75" r="3" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.75,12a8.362,8.362,0,0,0-1.23-2.52L15,12,12.48,9.48A8.362,8.362,0,0,0,11.25,12L9,18.75h3l.75,4.5h4.5l.75-4.5h3Z"
    />
  </svg>
</template>
